import { useEffect } from "react";
import Footer from "../../Common/Footer";
import LandingNavbar from "../../Common/LandingNavbar";
import "../PrivacyPolicy/PrivacyPolicy.css";

const SellerGuide = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LandingNavbar />
      <div className="privacy-page-container">
        <div className="container">
          <div className="privacy-container">
            <div className="privacy-policy-content">
              <h3> SVAD Seller Guidelines</h3>
              <div className="policy-details">
                <p>
                  1. Account Maintenance
                  <br />
                  Ensure your account details are accurate and secure. <br />{" "}
                  <br />
                  2. Listing Domains <br />
                  Provide comprehensive and honest descriptions of your domains.{" "}
                  <br />
                  Set realistic prices based on market trends and domain
                  valuations. <br /> <br />
                  3. Communication <br />
                  Respond promptly and clearly to buyer inquiries using the
                  platform's messaging system. <br /> <br />
                  4. Transaction Integrity <br />
                  Adhere to agreed timelines and terms of the sale. <br />
                  Use only SVAD-approved payment methods to ensure transaction
                  security. <br /> <br />
                  5. Dispute Handling <br />
                  Address disputes fairly and quickly. <br />
                  Cooperate with SVAD's dispute resolution process if needed.{" "}
                  <br /> <br />
                  6. Feedback <br />
                  Engage with buyer feedback constructively. <br />
                  Use feedback to improve your selling practices. <br /> <br />
                  7. Compliance
                  <br />
                  Follow all SVAD policies and applicable laws regarding domain
                  sales. <br /> <br />
                  8. Security Practices <br />
                  Protect your account from unauthorized access. <br />
                  Remain vigilant against phishing and scams. <br />
                  Adhering to these guidelines helps maintain a trustworthy and
                  efficient marketplace. For questions or assistance, contact
                  [support@svad.com].
                  <br /> <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SellerGuide;
