import React, { useState } from "react";
import LandingNavbar from "../../../Common/LandingNavbar";
import Button from "../../../components/ui/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { RiDeleteBin6Line } from "react-icons/ri";
import * as API from "../../../util/url";
import ApiCaller from "../../../util/ApiCaller";
import { toast } from "react-toastify";

import "./MakeOffer.css";
const MakeOffer = () => {
  //   const tldRegex = /\.(.com|.net|.org|.edu|.info|.io|.co|.ai|.tv|.online|.club|.co.uk|.us|.to|.me)$/i;
  const tldRegex =
    /\.(com|net|org|edu|info|io|co|ai|tv|online|club|us|to|me|info)$/i;

  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });
  const [domainStep, setDomainStep] = useState({
    step1: true,
    step2: false,
    step3: false,
  });
  const [domainStore, setDomainStore] = useState("");
  const [domainArray, setDomainArray] = useState([]);
  const [listDomain, setListDomain] = useState([]);

  const DomainStore = (value) => setDomainStore(value);

  const DomainSeprator = () => {
    console.log(domainStore, "dsa");
    if (tldRegex.test(domainStore)) {
      console.log("Valid TLD:");
      domainStore
        .trim()
        .split(/\s+/)
        .forEach((data) =>
          setDomainArray((prev) => [
            ...prev,
            {
              domainName: data,
              buyNowPrice: "",
              minimumPrice: "",
              currency: "USD",
            },
          ])
        );
      setDomainStep({ step2: true });
    } else {
      console.log("Invalid TLD:");
      toast("Invalid Domains", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const deleteDomain = (domain) =>
    setListDomain(listDomain.filter((data) => data !== domain));

  const listingDomains = () => {
    console.log(listDomain, "ld");
    if (listDomain.length === 0) {
      toast("Oops..! Please enter your Domain price.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      let data = listDomain.filter((data) => {
        if (data.buyNowPrice === "" && data.minimumPrice === "") {
          return;
        }
        return data;
      });
      console.log(data);
      if (data.length !== 0) {
        setListDomain(data);
        setDomainStep({ step3: true });
      } else {
        alert("Oops..! Please enter your Domain price.");
      }
    }
  };

  const onChangeHandler = (price, domainName, key) => {
    let arrayManager = [];
    domainArray.map((data) => {
      if (domainName === data.domainName) {
        data[key] = price;
      }
      arrayManager.push(data);
    });
    setListDomain(arrayManager);
  };
  const buyNowDomainHandler = () => {
    console.log(listDomain, "ld");
    setApiInfo({
      path: () => API.buyNowDomains({ data: listDomain }),
      redirectUrl: "/dashboard/domain-portfolio",
    });
  };

  return (
    <div>
      <LandingNavbar />
      <div className="makeoffer-page">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {/* step  1: Enter your desired domains */}

              {domainStep.step1 && (
                <div className="domainData">
                  <div>
                    <div className="text-start domainData-heading">
                      Enter Your Domain Name
                    </div>
                    <textarea
                      className="domainInput"
                      onChange={(e) => DomainStore(e.target.value)}
                      type="text"
                      rows={"10"}
                    />

                    <div className="d-flex" style={{ justifyContent: "end" }}>
                      <Button
                        type="button"
                        classData="btn loginButton w-auto"
                        click={DomainSeprator}
                      >
                        {" "}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {/* step 2 */}
              {domainStep.step2 && (
                <div className="domainData">
                  <div>
                    <div className="text-start">
                      Domain for listing({domainArray.length})
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Domain Name</th>
                          <th>Buy it Now</th>
                          <th>Make Offer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {domainArray?.map((data, index) => (
                          <tr id="domainArrayTable">
                            <td>{index + 1}</td>
                            <td>{data.domainName}</td>
                            <td>
                              {" "}
                              <InputGroup className="priceWidth">
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  aria-describedby="basic-addon1"
                                  onChange={(e) =>
                                    onChangeHandler(
                                      e.target.value,
                                      data.domainName,
                                      "buyNowPrice"
                                    )
                                  }
                                />
                              </InputGroup>
                            </td>
                            <td>
                              {" "}
                              <InputGroup className="priceWidth">
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  aria-describedby="basic-addon1"
                                  onChange={(e) =>
                                    onChangeHandler(
                                      e.target.value,
                                      data.domainName,
                                      "minimumPrice"
                                    )
                                  }
                                />
                              </InputGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex" style={{ justifyContent: "end" }}>
                      <Button
                        type="button"
                        classData="btn loginButton w-auto"
                        click={listingDomains}
                      >
                        {" "}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {/* step 3 */}
              {domainStep.step3 && (
                <div className="domainData">
                  <div>
                    <div className="text-start">
                      Domains for Preview ({listDomain.length})
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Domain Name</th>
                          <th>Buy it Now</th>
                          <th>Make Offer</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listDomain?.map((data, index) => (
                          <tr id="domainArrayTable">
                            <td>{index + 1}</td>
                            <td>{data.domainName}</td>
                            <td>
                              {data.buyNowPrice === ""
                                ? "-"
                                : `$${data.buyNowPrice}`}
                            </td>
                            <td>
                              {data.minimumPrice === ""
                                ? "-"
                                : `$${data.minimumPrice}`}
                            </td>
                            <td onClick={() => deleteDomain(data)}>
                              <RiDeleteBin6Line />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex" style={{ justifyContent: "end" }}>
                      <Button
                        type="button"
                        classData="btn loginButton w-auto"
                        click={buyNowDomainHandler}
                      >
                        {" "}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-4">
              <ol className="list-group vertical-steps mt-5 text-start ">
                <li className="list-group-item ">
                  <h6 style={{ fontWeight: 700 }}>Step 1</h6>
                  <div>
                    1. Portfolio is where you can list bulk of domains <br />
                    2. Input your domain name and details about your domain
                  </div>
                </li>
                <li className="list-group-item ">
                  <h6 style={{ fontWeight: 700 }}>Step 2</h6>
                  <div>1. Buyer has to pay for instant purchase</div>
                </li>
                <li className="list-group-item ">
                  <h6 style={{ fontWeight: 700 }}>Step 3</h6>
                  <div>Preview</div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <ApiCaller apiData={apiInfo} />
    </div>
  );
};

export default MakeOffer;
