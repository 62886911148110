import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as API from "../../util/url";

const FeatureListing = () => {
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();

  const listingData = async () => {
    try {
      let response = await API.getHomePageData();
      setListData(response.data?.map?.featured?.Domain);
      console.log(response.data?.map?.featured?.Domain, "rfd");
    } catch (error) {
      console.log(error);
    }
  };

  const listingHandler = (name) =>{
      navigate("/offer-preview", {
        state: name,
      })
  }

  useEffect(() => {
    listingData();
  }, []);
  return (
    <div>
      <div id="feature-listing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12-col-md-12 col-12 text-center">
              <h2 className="feature-heading">Featured listing</h2>
              <div className="line" />
            </div>
          </div>
          <div className="row">
            {listData?.map((data, index) => {
              return (
                <div key={index} className="col-lg-3 col-md-6 col-12">
                  <div className="feature-block-1">
                    <div className="feature-hover">
                      <p>{data.productName}</p>
                    </div>
                    <div className="domainCover">
                      <p className="feature-para-1">{data.productName}</p>
                      <img
                        src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707393491/icons8-heart-30_wdixcr.png"
                        alt="heart"
                        className="feature-img"
                      />
                    </div>
                    <p className="feature-para-2">
                      Show your clients you are open for business.
                    </p>
                    <div className="feature-listing-box mt-2">
                      <div className="listing-block">
                        <h5>Listing Type</h5>
                        <p>{data.listType}</p>
                      </div>
                      <div className="listing-block-1">
                        <h5>Length</h5>
                        <p>{data.productName.split(".")[0].length}</p>
                      </div>
                      <div className="listing-block-2">
                        <h5>Domain Age</h5>
                        <p>{data.age}</p>
                      </div>
                    </div>
                    <div className="price">
                      <h4>Current Price</h4>
                      <p>
                        {data.currentPrice} <span>SOL</span>
                      </p>
                      <div
                        className="preview"
                        style={{ cursor: "pointer" }}
                        onClick={() =>listingHandler(data.productName)}>
                        Preview
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureListing;
