import React from "react";

const SellBuy = () => {
  return (
    <div>
      <div id="work">
        <div className="container buy-sell-section">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-12"
              style={{ padding: "30px 3rem" }}
            >
              <div className="buyer">
                <h2>Domain Buyers ...</h2>
                <ul className="list">
                  <li>
                    Companies and startups searching for their ideal web address
                  </li>
                  <li>Building an online presence for new products</li>
                  <li>for brand protection</li>
                  <li>valuable domain names</li>
                  <li>
                    Concise internet domains for marketing and specific
                    campaigns
                  </li>
                </ul>
                {/* <a href="#" className="buy">
                  Domain Buyer...
                </a> */}
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-12"
              style={{ padding: "30px 3rem" }}
            >
              <div className="buyer">
                <h2>Domain Seller ...</h2>
                <ul className="list">
                  <li>Turning unused web addresses into money</li>
                  <li>Using a Broker to market premium domains</li>
                  <li>Earning money with Domain Parking</li>
                  <li>Professional trading of internet domains</li>
                  <li>
                    Launching a new domain extension/Top Level Domain (gTLD)
                  </li>
                </ul>
                {/* <a href="#" className="buy">
                  Domain Seller...
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellBuy;
