import React, { useEffect, useState } from "react";
import "./MarketPlace.css";
import LandingNavbar from "../../Common/LandingNavbar";
import Footer from "../../Common/Footer";
import Accordion from "react-bootstrap/Accordion";
import ReactSlider from "react-slider";
import * as API from "../../util/url";
import PageSize from "../../Common/PageSize";
import ProjectPagination from "../../Common/Pagination/ProjectPagination";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaSearch } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import useDebounce from "../../Common/useDebounce";
import { useNavigate, useLocation } from "react-router-dom";

let domainArray = [];
let statusArray = [];
let includesOnly = [];
let listType = ["auction", "makeOffer", "buyNow", "buyNowMakeOffer"];

const MarketPlace = () => {
  const debounce = useDebounce();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state, "loc");
  const [search, setSearch] = useState(false);
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(30);
  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [minLength, setMinLength] = useState(0);
  const [maxLength, setMaxLength] = useState(64);

  const [minTime, setMinTime] = useState(0);
  const [maxTime, setMaxTime] = useState(31);
  const [marketFilterData, setMarketFilterData] = useState([]);
  const handlePageSize = (data) => setPageSize(data);

  const initialState = {
    listType: ["auction", "makeOffer", "buyNow", "buyNowMakeOffer"],
    keyword: "", //searchBox
    searchType: "contains", //radio button
    noNumbers: false, //checkbox
    noDashes: false, //checkbox
    allNumbers: false, //checkbox
    tlds: [], //multiple checkbox
    minUsers: null, //inputbox Monthly User
    maxUsers: null, //inputbox Monthly User
    maxViews: null, //inputbox Monthly views
    minViews: null, //inputbox Monthly views
    minAge: 0, //inputbox age
    maxAge: 30, //inputbox age
    maxLength: 64, //Domain length
    minLength: 0, //Domain length
    maxTime: 31, //Time Remaining
    minTime: 0,
    includesType: [], //Includes Only
    status: [], //multiple checkbox
    maxPrice: null, //Price Range
    minPrice: null,
  };

  const [filterData, setFilterData] = useState({
    listType: ["auction", "makeOffer", "buyNow", "buyNowMakeOffer"],
    keyword: "", //searchBox
    searchType: "contains", //radio button
    noNumbers: false, //checkbox
    noDashes: false, //checkbox
    allNumbers: false, //checkbox
    tlds: [], //multiple checkbox
    minUsers: null, //inputbox Monthly User
    maxUsers: null, //inputbox Monthly User
    maxViews: null, //inputbox Monthly views
    minViews: null, //inputbox Monthly views
    minAge: 0, //inputbox age
    maxAge: 30, //inputbox age
    maxLength: 64, //Domain length
    minLength: 0, //Domain length
    maxTime: 31, //Time Remaining
    minTime: 0,
    includesType: [], //Includes Only
    status: [], //multiple checkbox
    maxPrice: null, //Price Range
    minPrice: null,
  });

  const searchType = [
    { title: "Contains", key: "contains" },
    { title: "End With", key: "endWith" },
    { title: "Exact", key: "exact" },
    { title: "Start With", key: "startWith" },
  ];
  const containsType = [
    { title: "No Number", key: "noNumbers" },
    { title: "No Dashes", key: "noDashes" },
    { title: "All Number", key: "allNumbers" },
  ];
  const tlds = [
    ".com",
    ".net",
    ".org",
    ".info",
    ".io",
    ".co",
    ".ai",
    ".tv",
    ".online",
    ".club",
    ".co.uk",
    ".us",
    ".to",
    ".me",
  ];

  const defaultHandler = () => {
    domainArray = [];
    statusArray = [];
    includesOnly = [];
    listType = ["auction", "makeOffer", "buyNow", "buyNowMakeOffer"];
  };

  const tldsHandler = (domain) => {
    console.log(domain, "dn");
    if (domainArray.includes(domain)) {
      domainArray = domainArray.filter((element) => element !== domain);
    } else {
      domainArray.push(domain);
    }
    setFilterData({ ...filterData, tlds: domainArray });
  };

  const statusHandler = (stat) => {
    if (statusArray.includes(stat)) {
      statusArray = statusArray.filter((element) => element !== stat);
    } else {
      statusArray.push(stat);
    }
    setFilterData({ ...filterData, status: statusArray });
  };

  const includeHandler = (data) => {
    if (includesOnly.includes(data)) {
      includesOnly = includesOnly.filter((element) => element !== data);
    } else {
      includesOnly.push(data);
    }
    setFilterData({ ...filterData, includesType: includesOnly });
  };

  const listTypeHandler = (data) => {
    if (listType.includes(data)) {
      listType = listType.filter((element) => element !== data);
    } else {
      listType.push(data);
    }
    setFilterData({ ...filterData, listType: listType });
  };

  const getdomainAge = (rangeValue) => {
    setMinAge(rangeValue[0]);
    setMaxAge(rangeValue[1]);
    setFilterData({
      ...filterData,
      minAge: rangeValue[0],
      maxAge: rangeValue[1],
    });
  };

  const getdomainLength = (rangeValue) => {
    setMinLength(rangeValue[0]);
    setMaxLength(rangeValue[1]);
    setFilterData({
      ...filterData,
      minLength: rangeValue[0],
      maxLength: rangeValue[1],
    });
  };

  const getTimeRemain = (rangeValue) => {
    setMinTime(rangeValue[0]);
    setMaxTime(rangeValue[1]);
    setFilterData({
      ...filterData,
      minTime: rangeValue[0],
      maxTime: rangeValue[1],
    });
  };

  const marketplaceFilterApi = async (pageNumber = 1, status, order) => {
    try {
      let response = await API.filterMarketPlace({
        minSize: pageNumber,
        maxSize: pageSize,
        ...filterData,
      });

      setMarketFilterData(response.data.map.marketplace);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApiCalls = () => {
    debounce(marketplaceFilterApi, 500);
  };

  useEffect(() => {
    handleApiCalls();
  }, [pageSize, filterData]);
  useEffect(() => {
    if (location.pathname === "/marketplace") {
      window.history.replaceState({}, "");
    }
    console.log(filterData, "fd");
  }, []);

  useEffect(() => {
    if (location.state !== "" || null) {
      setFilterData({
        ...filterData,
        keyword: location.state,
      });
      // marketplaceFilterApi();
    }
  }, [location]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LandingNavbar />
      <div className="marketplace-div-bg">
        <div className="container mt-2">
          <div className="row">
            <div className="col-lg-4 col-md-3 col-12 filtertop">
              <h1 className="text-start">Search Online Assets</h1>
            </div>
            <div className="col-lg-2 col-md-3 col-12 filtertop">
              <h1>Showing {pageSize} Result</h1>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="checks d-flex">
                <div className="check-marketplace-div">
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                    defaultChecked
                    onClick={() => listTypeHandler("auction")}
                  />
                  <label htmlFor="vehicle1">Auction</label>
                  <br />{" "}
                </div>
                <div className="check-marketplace-div">
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                    defaultChecked
                    onClick={() => listTypeHandler("makeOffer")}
                  />
                  <label htmlFor="vehicle1">Make Offer</label>
                  <br />{" "}
                </div>
                <div className="check-marketplace-div">
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                    className="custom-control-input"
                    defaultChecked
                    onClick={() => listTypeHandler("buyNow")}
                  />
                  <label htmlFor="vehicle1" className="custom-control-label">
                    Buy Now
                  </label>
                  <br />
                </div>
                <div className="check-marketplace-div">
                  <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                    className="custom-control-input"
                    defaultChecked
                    onClick={() => listTypeHandler("buyNowMakeOffer")}
                  />
                  <label htmlFor="vehicle1" className="custom-control-label">
                    Buy Now and Make Offer
                  </label>
                  <br />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container market mt-3">
          <div className="row">
            <div className="col-lg-4 col-md-12" id="filterLeftSide">
              <div id="mySidenav" className="sidenav openNav">
                <div className="searchInput pb-3 pt-3">
                  <div className="position-relative">
                    {/* <p className="container fontSize14 font-weight-bold mb-3">Keyword</p> */}
                    <div className="marketplace__filterSearch">
                      <form id="searchMarketpalce">
                        <InputGroup className="my-3">
                          <Form.Control
                            placeholder="Search Keyword..."
                            aria-label="keyword"
                            aria-describedby="basic-addon1"
                            className="keywordSearch"
                            defaultValue={location.state}
                            onChange={(e) =>
                              setFilterData({
                                ...filterData,
                                keyword: e.target.value,
                              })
                            }
                          />
                          {/* <InputGroup.Text
                            className="buttonSearch"
                            id="basic-addon1"

                            // onClick={()=>{setSearch(!search)}}
                          >

                            <AiOutlineClose />
                          </InputGroup.Text> */}
                        </InputGroup>
                      </form>
                    </div>
                  </div>
                  <div className="row m-0 pt-2 marketplaceRadio">
                    <div id="searchType" className="row">
                      {searchType.map(({ title, key }) => {
                        return (
                          <div key={key} className="col-md-6">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="opt1"
                                defaultValue={key}
                                name="searchType"
                                className="custom-control-input contains"
                                defaultChecked={key === "contains" ? true : ""}
                                onClick={() =>
                                  setFilterData({
                                    ...filterData,
                                    searchType: key,
                                  })
                                }
                              />
                              <label
                                htmlFor="opt1"
                                className="custom-control-label"
                              >
                                {title}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-12 col-5 display414None">
                      <hr />
                    </div>
                    <div id="excludes">
                      {containsType.map(({ title, key }) => {
                        return (
                          <div key={key} className="col-md-12 pr-0">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                id="noNumbers"
                                name="noNumbers"
                                className="custom-control-input"
                                onClick={(e) =>
                                  setFilterData({
                                    ...filterData,
                                    [key]: e.target.checked,
                                  })
                                }
                              />
                              <label className="custom-control-label">
                                {title}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <Accordion defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> TLD's</Accordion.Header>
                    <Accordion.Body>
                      <div className="card-body">
                        <div className="row" id="tldsDiv">
                          {tlds.map((domain) => {
                            return (
                              <div key={domain} className="col-md-6">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    // defaultValue={domain}
                                    id={domain}
                                    name={domain}
                                    className="custom-control-input"
                                    onClick={() => tldsHandler(domain)}
                                  />
                                  <label
                                    htmlFor={domain}
                                    className="custom-control-label"
                                  >
                                    {domain}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="1">
                  <Accordion.Header> Monthly User</Accordion.Header>
                  <Accordion.Body>
                    <div className="card-body">
                      <div className="row m-0">
                        <div className="col-md-5">
                          <input
                            type="number"
                            className="form-control"

                            placeholder="Min"
                            id="minUsers"
                            name="minUsers"
                            defaultValue=""
                            fdprocessedid="7edvw5"
                            onChange={(e) => setFilterData({ ...filterData, minUsers: e.target.value })}
                          />
                        </div>
                        <div className="col-md-1 monthlyUserBetweenDash">-</div>
                        <div className="col-md-5">
                          <input
                            type="number"
                            className="form-control"

                            placeholder="Max"
                            id="maxUsers"
                            name="maxUsers"
                            defaultValue=""
                            fdprocessedid="2477ci"
                            onChange={(e) => setFilterData({ ...filterData, maxUsers: e.target.value })}

                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header> Monthly Pageviews</Accordion.Header>
                  <Accordion.Body>
                    <div className="card-body">
                      <div className="row m-0">
                        <div className="col-md-5">
                          <input
                            type="text"
                            className="form-control"

                            placeholder="Min"
                            id="minViews"
                            name="minViews"
                            defaultValue=""
                            fdprocessedid="g95vpn"
                            onChange={(e) => setFilterData({ ...filterData, minViews: e.target.value })}

                          />
                        </div>
                        <div
                          className="col-md-1"
                          style={{
                            padding: 0,
                            textAlign: "center",
                            display: "block",
                            alignSelf: "center",
                          }}
                        >
                          -
                        </div>
                        <div className="col-md-5">
                          <input
                            type="text"
                            className="form-control"

                            placeholder="Max"
                            id="maxViews"
                            name="maxViews"
                            defaultValue=""
                            fdprocessedid="9rnu8"
                            onChange={(e) => setFilterData({ ...filterData, maxViews: e.target.value })}

                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Age</Accordion.Header>
                    <Accordion.Body>
                      <div className="card-body">
                        <div className="range-slide">
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            defaultValue={[minAge, maxAge]}
                            value={[minAge, maxAge]}
                            min={0}
                            max={30}
                            ariaLabel={["Lower thumb", "Upper thumb"]}
                            ariaValuetext={(state) =>
                              `Thumb value ${state.valueNow}`
                            }
                            onChange={(value) => getdomainAge(value)}
                            renderThumb={(props, state) => {
                              return <div {...props}>{state.valueNow}</div>;
                            }}
                            pearling
                            minDistance={10}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header> Domain Length</Accordion.Header>
                    <Accordion.Body>
                      <div className="card-body">
                        <div className="range-slide">
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            defaultValue={[minLength, maxLength]}
                            value={[minLength, maxLength]}
                            min={0}
                            max={64}
                            ariaLabel={["Lower thumb", "Upper thumb"]}
                            ariaValuetext={(state) =>
                              `Thumb value ${state.valueNow}`
                            }
                            onChange={(value) => getdomainLength(value)}
                            renderThumb={(props, state) => {
                              return <div {...props}>{state.valueNow}</div>;
                            }}
                            pearling
                            minDistance={10}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header> Time Remaining</Accordion.Header>
                    <Accordion.Body>
                      <div className="card-body">
                        <div className="range-slide">
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            defaultValue={[minTime, maxTime]}
                            value={[minTime, maxTime]}
                            min={0}
                            max={31}
                            ariaLabel={["Lower thumb", "Upper thumb"]}
                            ariaValuetext={(state) =>
                              `Thumb value ${state.valueNow}`
                            }
                            onChange={(value) => getTimeRemain(value)}
                            renderThumb={(props, state) => {
                              return <div {...props}>{state.valueNow}</div>;
                            }}
                            pearling
                            minDistance={10}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header> Includes Only</Accordion.Header>
                    <Accordion.Body>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                defaultValue="verified_revenue"
                                id="verified_revenue"
                                name="verified_revenue"
                                className="custom-control-input"
                                onClick={() =>
                                  includeHandler("verified_revenue")
                                }
                              />{" "}
                              <label
                                htmlFor="verified_revenue"
                                className="custom-control-label"
                              >
                                Verified Revenue{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                defaultValue="verified_traffic"
                                id="verified_traffic"
                                name="verified_traffic"
                                className="custom-control-input"
                                onClick={() =>
                                  includeHandler("verified_traffic")
                                }
                              />{" "}
                              <label
                                htmlFor="verified_traffic"
                                className="custom-control-label"
                              >
                                Verified Traffic{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                defaultValue="reserve_met"
                                id="reserve_met"
                                name="reserve_met"
                                className="custom-control-input"
                                onClick={() => includeHandler("reserve_met")}
                              />{" "}
                              <label
                                htmlFor="reserve_met"
                                className="custom-control-label"
                              >
                                Reserve Met{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header> Price Range</Accordion.Header>
                    <Accordion.Body>
                      <div className="card-body">
                        <div className="row m-0">
                          <div className="col-md-5 price-range-input">
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              placeholder="$Min"
                              id="minPrice"
                              name="minPrice"
                              defaultValue=""
                              fdprocessedid="fbu969"
                              onChange={(e) =>
                                setFilterData({
                                  ...filterData,
                                  minPrice: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div
                            className="col-md-1"
                            style={{
                              padding: 0,
                              textAlign: "center",
                              display: "block",
                              alignSelf: "center",
                            }}
                          >
                            -
                          </div>
                          <div className="col-md-5 price-range-input ">
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              placeholder="$Max"
                              id="maxPrice"
                              name="maxPrice"
                              defaultValue=""
                              fdprocessedid="m6gb3r"
                              onChange={(e) =>
                                setFilterData({
                                  ...filterData,
                                  maxPrice: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* <Accordion.Item eventKey="9">
                  <Accordion.Header>Status</Accordion.Header>
                  <Accordion.Body>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              defaultValue="unsold"
                              id="unsold"
                              className="custom-control-input"

                              onClick={() => statusHandler("unsold")}

                            />{" "}
                            <label
                              htmlFor="unsold"
                              className="custom-control-label"
                            >
                              {" "}
                              Open{" "}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              defaultValue="sold"
                              id="sold"
                              className="custom-control-input"
                              onClick={() => statusHandler("sold")}

                            />{" "}
                            <label
                              htmlFor="sold"
                              className="custom-control-label"
                            >
                              {" "}
                              Recently Sold{" "}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              defaultValue="auctionEnded"
                              id="auctionEnded"
                              className="custom-control-input"
                              onClick={() => statusHandler("auctionEnded")}


                            />{" "}
                            <label
                              htmlFor="auctionEnded"
                              className="custom-control-label"
                            >
                              Auction Ended
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
                </Accordion>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-12 mt-5">
              <div className="container">
                {marketFilterData.map((data) => {
                  return (
                    <div key={data.productName} className="row tableborder">
                      <div className="col-lg-6 col-md-6 col-6 infocol">
                        <h6 className="domainName">{data.productName}</h6>
                        <div className="registrar">
                          <p>Domain seller</p>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-6 btncol">
                        {data.listType !== "auction" ? (
                          <button
                            className="offerbtn"
                            onClick={() => {
                              navigate("/offer-preview", {
                                state: data.productName,
                              });
                              defaultHandler();
                            }}
                          >
                            Preview
                          </button>
                        ) : (
                          <button
                            className="offerbtn"
                            onClick={() => {
                              setFilterData(initialState);
                              navigate("/auction-preview", { state: data.url });
                              defaultHandler();
                            }}
                          >
                            Auction
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}

                {marketFilterData.length==0&&<div>No Record Found</div>}

                <div
                  className="pb-5 mt-2"
                  style={{ position: "relative", height: "100px" }}
                >
                  {dataCount > pageSize && (
                    <ProjectPagination
                      handlePageClick={(e) =>
                        marketplaceFilterApi(e.selected + 1)
                      }
                      pageCount={pageCount}
                    />
                  )}

                  <div className="pageSizeContainer">
                    <PageSize
                      title={`${pageSize} items`}
                      handleData={(e) => handlePageSize(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MarketPlace;
