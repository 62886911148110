import React from "react";
import { useState, useEffect } from "react";
// import * as API from "../../../util/url";
import * as API from "../../util/url";
import { Formik, useFormik } from "formik";
import ApiCaller from "../../util/ApiCaller";
import { useLocation } from "react-router-dom/dist";

const Profiletab = () => {
  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });
  const [domaindata, setDomainData] = useState({});
  const location = useLocation();
  const initialValues = {
    username: "",
    // email: "",
    // address: "",
    // phoneNumber: "",
    // zipCode: "",
    // city: "",
    // country: "",
    // state: "",
    bio: "",
    // image: ""
  };
  const formValues = {
    username: domaindata.username,
    bio: domaindata.bio,
  };
  const formik = useFormik({
    initialValues: formValues || initialValues,
    onSubmit: async (data, onSubmitProps) => {
      setApiInfo({ path: () => API.updateProfile(data) });
      localStorage.setItem("userName", data.username);
    },
  });

  const onSubmit = async (data, onSubmitProps) => {
    setDomainData({ path: () => API.getProfile(data) });
  };

  const getProfileData = async () => {
    try {
      let response = await API.getProfile();
      console.log(response.data.map.username, "rsp");
      console.log(response.data.map.bio, "rsp");
      setDomainData(response.data.map);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div className="container" style={{ marginTop: "3rem" }}>
      <div className="row">
        <div className="col-12 text-start multi_step_form">
          <h3 className="headingtab">Profile</h3>
          <hr className="hrline" />
          <Formik
            onSubmit={onSubmit}
            // validationSchema={validationSchema}
            enableReinitialize
            // initialValues={formValues || initialValues}
          >
            <form
              action=""
              className="profile_form"
              onSubmit={formik.handleSubmit}
            >
              <label htmlFor="fname" className="">
                User Name:
              </label>
              <br />
              <input
                className="inputuser"
                type="text"
                id="fname"
                name="username"
                placeholder="Username"
                onChange={formik.handleChange}
                defaultValue={formValues.username}
              />
              <br />
              <label className="mt-4" htmlFor="about">
                About:
              </label>
              <br />
              <textarea
                id="w3review"
                name="bio"
                rows="4"
                cols="50"
                className="textareainput inputusers"
                onChange={formik.handleChange}
                defaultValue={formValues.bio}
              />
              <br />

              <button type="submit" className="submitbtns">
                Save Changes
              </button>
            </form>
          </Formik>
        </div>
      </div>
      <ApiCaller apiData={apiInfo} />
    </div>
  );
};

export default Profiletab;
