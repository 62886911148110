import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import * as API from "../../../util/url";

const AdminDashboard = () => {
  const [domainData, setDomainData] = useState({});
  const getAdminData = async (data) => {
    console.log(data);
    try {
      let response = await API.adminDashboarData(data);
      console.log(response.data);
      setDomainData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAdminData();
  }, []);
  return (
    <>
      <div>
        <div className="page_tittle">
          <h3 className="px-3">Dashboard</h3>
        </div>
        <div>
          <h4 className="text-start px-4">Analytics</h4>
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-3 mt-4">
                <Card
                  className="card-admin-dash"
                  // style={{ background: "#DCEAF9" }}
                >
                  <Card.Body className="text-center flex-column d-flex justify-content-center">
                    <Card.Title className="title-card-admin-dash">
                      Total Domains
                    </Card.Title>
                    <Card.Subtitle className="mt-1 text-white">
                      <h4> {domainData.totalDomains}</h4>
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-3 mt-4">
                <Card
                  className="card-admin-dash"
                  // style={{ background: "#DCEAF9" }}
                >
                  <Card.Body className="text-center flex-column d-flex justify-content-center">
                    <Card.Title className="title-card-admin-dash">
                      Buy /Make Domains
                    </Card.Title>
                    <Card.Subtitle className="mt-1 text-white">
                      <h4> {domainData.buyMakeDomains}</h4>
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </div>{" "}
              <div className="col-md-3 mt-4">
                <Card
                  className="card-admin-dash"
                  // style={{ background: "#DCEAF9" }}
                >
                  <Card.Body className="text-center flex-column d-flex justify-content-center">
                    <Card.Title className="title-card-admin-dash">
                      Auction Domains
                    </Card.Title>
                    <Card.Subtitle className="mt-1 text-white">
                      <h4> {domainData.auctionDomains} </h4>
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </div>{" "}
              <div className="col-md-3 mt-4">
                <Card
                  className="card-admin-dash"
                  // style={{ background: "#DCEAF9" }}
                >
                  <Card.Body className="text-center flex-column d-flex justify-content-center">
                    <Card.Title className="title-card-admin-dash">
                      Domains Sold
                    </Card.Title>
                    <Card.Subtitle className="mt-1 text-white">
                      <h4> {domainData.domainsSold}</h4>
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </div>{" "}
              <div className="col-md-3 mt-4">
                <Card
                  className="card-admin-dash"
                  // style={{ background: "#DCEAF9" }}
                >
                  <Card.Body className="text-center flex-column d-flex justify-content-center">
                    <Card.Title className="title-card-admin-dash">
                      Revenue from Domains
                    </Card.Title>
                    <Card.Subtitle className="mt-1 text-white">
                      <h4> ${domainData.revenueFromDomains} </h4>
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </div>{" "}
              <div className="col-md-3 mt-4">
                <Card
                  className="card-admin-dash"
                  // style={{ background: "#DCEAF9" }}
                >
                  <Card.Body className="text-center flex-column d-flex justify-content-center">
                    <Card.Title className="title-card-admin-dash">
                      Total Users
                    </Card.Title>
                    <Card.Subtitle className="mt-1 text-white">
                      <h4> {domainData.totalUsers} </h4>
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
