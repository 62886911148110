import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import LandingNavbar from "./LandingNavbar";
import SideNav from "./SideNav";
import Footer from "./Footer";
import "./WebsiteLayout.css";
const WebsiteLayout = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (localStorage.getItem("bestUrl")) {
  //     navigate("/dashboard");
  //   } else {
  //     navigate('/signin');
  //   }

  //   // eslint-disable-next-line
  // }, [localStorage.getItem("bestUrl")]);

  return (
    <>
      <div
        className="CommonComponent"
        style={{ background: "aliceblue", overflow: "hidden" }}
      >
        <LandingNavbar />
        <div className="">
          <div className="row">
            <div className=" col-lg-2 col-md-12 sidenav-bg">
              <SideNav />
            </div>
            <div className="Mainbody col-lg-10 col-md-12">
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default WebsiteLayout;
