import React, { useState, useEffect } from "react";
import LandingNavbar from "../../Common/LandingNavbar";
import "./AuctionPreview.css";
import { useLocation, useNavigate } from "react-router-dom";
import { UseSelector, useDispatch } from "react-redux";
import Button from "../../components/ui/Button";
import AuctionBidding from "../../Common/Modal/AuctionBidding/AuctionBidding";
import * as API from "../../util/url";
import { setBidUrl } from "../../util/store/slices/bidDomainUrlSlice";

const AuctionPreview = () => {
  const [auctionBiddingModal, setAuctionBiddingModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  dispatch(setBidUrl(location.state));

  localStorage.setItem("domainUrl", location.state);
  const [domainDetail, setDomainDetail] = useState([]);

  const getDomainDetail = async () => {
    try {
      let response = await API.getBiddingData({
        url: location.state,
      });
      setDomainDetail(response.data.map);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDomainDetail();
  }, []);

  const loginHandler = () =>{
    if(localStorage.getItem('bestUrl')!==null){
      setAuctionBiddingModal(true)
    }else{
      navigate("/signin");
    }
  }

  return (
    <div>
      <LandingNavbar />
      <section id="auctionPreview">
        <div className="container">
          <div className="auctionOuterSection">
            <div className="auctionMainSection">
              <h1 className="domainNameAuction">{domainDetail.productName}</h1>
              <h3 className="">is for auction</h3>
              <p className="">
                Questions? Talk to a domain expert:{" "}
                <span style={{ color: "#80ecff" }}> +1 (425) 880-0977</span>
              </p>

              <div className="row">
                <div className="col-md-3">
                  <div className="">Age</div>
                  <p className=" fw-700">{domainDetail.age}</p>
                </div>
                <div className="col-md-6">
                  <div className="">Registrar</div>
                  <p className=" fw-700">{domainDetail.registrar}</p>
                </div>
                <div className="col-md-3">
                  <div className="">Expires</div>
                  <p className=" fw-700">{domainDetail.expiry}</p>
                </div>
              </div>
              <br />
              <Button click={loginHandler}>
                Auction
              </Button>
            </div>
          </div>
        </div>
      </section>
      <AuctionBidding
        domainData={domainDetail}
        show={auctionBiddingModal}
        onHide={() => setAuctionBiddingModal(false)}
      />
    </div>
  );
};

export default AuctionPreview;
