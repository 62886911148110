import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import { FaTable } from "react-icons/fa";

const AdminSideNav = () => {
  const { userName } = useSelector((state) => state.auth);
  const location = useLocation();

  const initialValues = {
    dashboard: false,
    profile: false,
    myListing: false,
    masterTable: false,
    offer: false,
    transactionCenter: false,
    // watchList:false,
    email: false,
    bidPlaced: false,
    domainSold: false,
  };

  const [sideActive, setSideActive] = useState(initialValues);

  useEffect(() => {
    console.log(location.pathname.split("/")[2], "loc");
    if (location.pathname?.split("/")[2] !== undefined) {
      if (
        location.pathname?.split("/")[2] === "draft" ||
        "domain-portfolio" ||
        "auction"
      ) {
        setSideActive({ ...initialValues, myListing: true });
      } else {
        setSideActive({
          ...initialValues,
          [location.pathname?.split("/")[2]]: true,
        });
      }
      // draft , domain-portfolio ,auction
    } else {
      setSideActive({ ...initialValues, dashboard: true });
    }
  }, []);

  return (
    <nav className="navbar navbar-expand-lg mb-5" style={{ padding: "0px" }}>
      {/* <a className="navbar-brand" href="#">Navbar</a> */}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContents"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContents">
        <div className="profile-sidenav-data">
          <div className="w-100">
            <div
              className={
                sideActive.dashboard ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() =>
                setSideActive({ ...initialValues, dashboard: true })
              }
            >
              <div className="sidenavImage">
                {/* <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707480887/laptop_vs8osx.png"
                  alt=""
                /> */}
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712580774/laptop_vs8osx_2_1_ofuh0g.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/power/dashboard">
                  Dashboard
                </NavLink>
              </div>
            </div>
            <div
              className={
                sideActive.profile ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() => setSideActive({ ...initialValues, profile: true })}
            >
              <div className="sidenavImage">
                {/* <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707480887/user_g4lltu.png"
                  alt=""
                /> */}
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712580961/user_g4lltu_1_1_zggech.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/power/dashboard/allaccounts">
                  All Accounts
                </NavLink>
              </div>
            </div>
            <div
              onClick={() =>
                setSideActive({ ...initialValues, myListing: true })
              }
            >
              <div className="">
                <Accordion id="myListing" defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      className={
                        sideActive.myListing
                          ? "sidenavSingle active"
                          : "sidenavSingle"
                      }
                    >
                      <div className="sidenavImage">
                        {/* <img
                          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707541413/shopping-cart_jhdbla.png"
                          alt=""
                        /> */}
                        <img
                          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712661729/shopping-cart_jhdbla_1_1_lg41vm.png"
                          alt=""
                        />
                      </div>
                      My Listing
                    </Accordion.Header>
                    <Accordion.Body>
                      <NavLink
                        className="item"
                        to="/power/dashboard/all-auction-listings"
                      >
                        Auction Listing
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/all-domain-portfolio"
                      >
                        Domain Portfolio Listing
                      </NavLink>
                      {/* <NavLink className="item" to="/dashboard/draft">
                        My Draft
                      </NavLink> */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div
              onClick={() =>
                setSideActive({ ...initialValues, masterTable: true })
              }
            >
              <div className="">
                <Accordion id="myListing" defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      className={
                        sideActive.masterTable
                          ? "sidenavSingle active"
                          : "sidenavSingle"
                      }
                    >
                      <div className="sidenavImage">
                        <FaTable />
                      </div>
                      Master Table
                    </Accordion.Header>
                    <Accordion.Body>
                      <NavLink
                        className="item"
                        to="/power/dashboard/all-bidding"
                      >
                        Bidding
                      </NavLink>

                      <NavLink
                        className="item"
                        to="/power/dashboard/all-domains"
                      >
                        All Domains
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/all-filter"
                      >
                        All Filter
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/all-make-offer-message"
                      >
                        Make Offer Message
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/all-make-offer-data"
                      >
                        Make Offer Data
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/notification"
                      >
                        Notification
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/admin-user"
                      >
                        User
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/admin-Watchers"
                      >
                        Watchers
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/admin-roles"
                      >
                        Roles
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/admin-userroles"
                      >
                        User Roles
                      </NavLink>
                      {/* <NavLink
                   className="item"
                   to="/power/dashboard/admin-contact-us"
                 >
                Contact Us
                 </NavLink> */}
                      <NavLink
                        className="item"
                        to="/power/dashboard/admin-domain-report-data"
                      >
                        Domain Report
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/admin-domain-report-status"
                      >
                        Report status
                      </NavLink>
                      {/* <NavLink
                   className="item"
                   to="/power/dashboard/admin-EmailData"
                 >
               Email Data
                 </NavLink> */}
                      {/* <NavLink
                   className="item"
                   to="/power/dashboard/admin-PatternData"
                 >
               Pattern Data
                 </NavLink> */}
                      <NavLink
                        className="item"
                        to="/power/dashboard/payment-message"
                      >
                        Payment Message
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/payment-main-chat"
                      >
                        Payment Chat Data
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/power/dashboard/payment-track"
                      >
                        Payment Track data
                      </NavLink>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div
              className={
                sideActive.offer ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() => setSideActive({ ...initialValues, offer: true })}
            >
              <div className="sidenavImage">
                {/* <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707541413/data-transfer_jhdsip.png"
                  alt=""
                /> */}
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816499/data-transfer_jhdsip_2_1_1_uyo50e.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink
                  className="Links"
                  to="/power/dashboard/admin-get-make-offer"
                >
                  Message & Offer
                </NavLink>
              </div>
            </div>
            <div
              className={
                sideActive.transactionCenter
                  ? "sidenavSingle active"
                  : "sidenavSingle"
              }
              onClick={() =>
                setSideActive({ ...initialValues, transactionCenter: true })
              }
            >
              <div className="sidenavImage">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816499/data-transfer_jhdsip_2_1_1_uyo50e.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink
                  className="Links"
                  to="/power/dashboard/payment-history"
                >
                  Payment History
                </NavLink>
              </div>
            </div>
            {/* <div className={sideActive.watchList?"sidenavSingle active":"sidenavSingle"} onClick={()=>setSideActive({...initialValues,watchList:true})}>
            <div className="sidenavImage">
              <img
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1705471913/icons8-eye-50_nfnbbs.png"
                alt=""
              />
            </div>
            <div className="">
              <NavLink className="Links" to="/dashboard/watchlist">
                Watch List
              </NavLink>
            </div>
          </div> */}
            <div
              className={
                sideActive.email ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() => setSideActive({ ...initialValues, email: true })}
            >
              <div className="sidenavImage">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816782/wallet_vuwscq_1_1_nt5woa.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/power/dashboard/email-content">
                  Email
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminSideNav;
