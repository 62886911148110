import { useEffect } from "react";
import Footer from "../../Common/Footer";
import LandingNavbar from "../../Common/LandingNavbar";
import "../PrivacyPolicy/PrivacyPolicy.css";

const BuyerGuide = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LandingNavbar />
      <div className="privacy-page-container">
        <div className="container">
          <div className="privacy-container">
            <div className="privacy-policy-content">
              <h3> SVAD Buyer Guidelines</h3>
              <div className="policy-details">
                <p>
                  1. Account Maintenance
                  <br />
                  Keep your account information up-to-date and secure. <br />{" "}
                  <br />
                  2. Transaction Procedures
                  <br />
                  Thoroughly review domain details before making offers. <br />
                  Use only approved payment methods. <br /> <br />
                  3. Communication <br />
                  Communicate clearly with sellers using our platform’s
                  messaging system. <br /> <br />
                  4. Due Diligence <br />
                  Conduct checks on domains for trademarks and existing usage.{" "}
                  <br />
                  Understand market values to make informed decisions. <br />{" "}
                  <br />
                  5. Dispute Resolution
                  <br />
                  Promptly report transaction issues and engage cooperatively in
                  resolving disputes. <br /> <br />
                  6. Feedback <br />
                  Provide honest feedback based on your transaction experiences.{" "}
                  <br /> <br />
                  7. Policy Compliance <br />
                  Follow all SVAD policies and legal regulations related to
                  domain transactions. <br /> <br />
                  8. Security Practices
                  <br />
                  Protect your login information and be cautious of online
                  scams. <br />
                  By adhering to these guidelines, you help maintain a secure
                  and efficient marketplace on SVAD. For any questions or
                  assistance, contact [support@svad.com]. <br /> <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </div>
  );
};

export default BuyerGuide;
