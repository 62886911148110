import React from "react";
import { useState, useEffect } from "react";
import * as API from "../../util/url";
import { useNavigate, useLocation } from "react-router-dom";


const Listing = () => {
  const [domainData, setDomainData] = useState({});
  const navigate = useNavigate();


  const DomainDetail = async (pageNumber = 1, status, order) => {
    try {
      let response = await API.getFrontData({
        pageNumber: 1,
        size: 8,
      });
      console.log(response.data.map, "rsp");

      setDomainData(response.data.map);
    } catch (error) {
      console.log(error);
    }
  };

  const listingHandler = (listType,domainId) =>{
      if(listType==='auction'){
        navigate("/auction-preview", { state: domainId});
      }else{
        navigate("/offer-preview", {
          state: domainId,
        });
      }


  }
  useEffect(() => {
    DomainDetail();
  }, []);
  return (
    <>
      <div>
        <div id="listing">
          <div className="container">
            <div className="row domain-list">
              <div className="col-lg-4 col-md-6 col-12 my-3">
                <div className="domain-listbox">
                  <h3 className="list-heading">
                    Showcase Domain
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707393863/icons8-play-30_ux4ngf.png"
                      alt="play"
                      style={{
                        marginTop: 3,
                        marginLeft: 7,
                        width: 15,
                        height: 15,
                      }}
                    />
                  </h3>

                  {domainData?.showcase?.map((data)=>(
                  <ul className="block-list" style={{cursor:'pointer'}} onClick={()=>listingHandler(data.listType,data.name)}>
                    <li>
                      <span>
                     
                      <span>{data.listType==='auction'?data.name.split('-')[0]:data.name}</span>
                        </span>
                      <span className="span-1">{data.price} SOL</span>
                    </li>
                  </ul>
                  ))}
                
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 my-3">
                <div className="domain-listbox">
                  <h3 className="list-heading list-heading-center">
                    Top Domain
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712732682/icons8-play-30_ux4ngf_1_1_nth3ic.png"
                      alt="play"
                      style={{
                        marginTop: 3,
                        marginLeft: 7,
                        width: 15,
                        height: 15,
                      }}
                    />
                  </h3>
                  {domainData?.topDomains?.map((data)=>(
                  <ul className="block-list"  style={{cursor:'pointer'}} onClick={()=>listingHandler(data.listType,data.name)}>
                    <li>
                      <span>{data.listType==='auction'?data.name.split('-')[0]:data.name}</span>
                      <span className="span-1">{data.price} SOL</span>
                    </li>
                  </ul>
                  ))}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 my-3">
                <div className="domain-listbox">
                  <h3 className="list-heading listing_block">
                    Auction Domain
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707393863/icons8-play-30_ux4ngf.png"
                      alt="play"
                      style={{
                        marginTop: 3,
                        marginLeft: 7,
                        width: 15,
                        height: 15,
                      }}
                    />
                  </h3>
                  {domainData?.auctionDomains?.map((data)=>(
                  <ul className="block-list"  style={{cursor:'pointer'}} onClick={()=>listingHandler(data.listType,data.name)}>
                    <li>
                      <span>{data.name.split('-')[0]}</span>
                      <span className="span-1">{data.price} SOL</span>
                    </li>
                  </ul>
                  ))}
                </div>
              </div>
              {/* <div className="col-lg-1" /> */}
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default Listing;
