import React from "react";
import "./PageNotFound.css";
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div id="page-not-found">
      <div className="number-found">404</div>
      <div className="text-found">
        <span>Ooops...</span>
        <br />
        Page not found
      </div>
      <NavLink
        to="/"
        className="buy mt-3"
        style={{ background: "green", color: "white" }}
      >
        Home{" "}
      </NavLink>
    </div>
  );
};

export default PageNotFound;
