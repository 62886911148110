import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideNav from "./SideNav";
import Footer from "./Footer";
import AdminNavbar from "./AdminNavbar";
import AdminSideNav from "./AdminSideNav";
const AdminLayout = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //     if (localStorage.getItem("bestUrl") && localStorage.getItem("grant") === 'ADMIN') {
  //         navigate("/power/dashboard");`                                                             
  //     } else if (localStorage.getItem("bestUrl")) {
  //         navigate("/");
  //     } else {
  //         navigate('/signin');
  //     }

  //     // eslint-disable-next-line
  // }, [localStorage.getItem("bestUrl")]);
  return (
    <div>
      <div>
        <div
          className="CommonComponent"
          style={{ background: "aliceblue", overflow: "hidden" }}
        >
          <AdminNavbar />
          <div className="admin-lay">
            <div className="row">
              <div className=" col-lg-2 col-md-12 sidenav-bg">
                <AdminSideNav />
              </div>

              <div className="Mainbody col-lg-10 col-md-12">
                <Outlet />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
