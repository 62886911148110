import React, { useEffect, useState } from "react";
import "./Auction.css";
import { NavLink } from "react-router-dom";
import Moment from "moment";
import TableComponent from "../../Common/TableComponent/TableComponent";
import * as API from "../../util/url";
import { TableFilterHook } from "../../Common/TableFilterHook";
import PageSize from "../../Common/PageSize";
import ProjectPagination from "../../Common/Pagination/ProjectPagination";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Auction = () => {
  const navigate = useNavigate();

  const tableColumns = [
    {
      accessorKey: "domainName",
      header: "Product Name",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "expiryDate",
      header: "Time Left",
      cell: (props) => (
        <div>{Moment(props.getValue()).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      accessorKey: "bids",
      header: "Bids",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "startingPrice",
      header: "	Starting Price",
      cell: (props) => <div>{props.getValue()} SOL</div>,
    },

    {
      accessorKey: "reservePrice",
      header: "Reserve Price",
      cell: (props) => <div>{props.getValue()} SOL</div>,
    },
    {
      accessorKey: "currentPrice",
      header: "Current Price",
      cell: (props) => <div>{props.getValue()} SOL</div>,
    },
    {
      accessorKey: "buyNowPrice",
      header: "Buynow Price",
      cell: (props) => <div>{props.getValue()} SOL</div>,
    },
    {
      accessorKey: "domainId",
      header: "Action",
      cell: (props) => (
        <div className="d-flex" style={{ justifyContent: "space-around" }}>
          {" "}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => editDomain(props.getValue())}
          >
            {" "}
            <FaRegEdit style={{ fontSize: "25px" }} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => deleteDomain(props.getValue())}
          >
            {" "}
            <MdOutlineDelete style={{ fontSize: "25px" }} />
          </div>
        </div>
      ),
    },
    {
      accessorKey: "verified",
      header: "Verification",
      cell: (props) => (
        <div className=" badge-success tableverificaion">
          {props.getValue() === true ? "verified" : "rejected"}
        </div>
      ),
    },

    // {
    //   enableSorting: false,
    //   accessorKey: 'verifiedStatus',
    //   header: 'Status',
    //   cell: (props) => <button className={props.getValue()}>{props.getValue()}</button>
    // },
  ];

  const [auctionDomain, setAuctionDomain] = useState([]);
  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const tableFilter = (data) => {
    TableFilterHook(data, AuctionDomains);
  };
  const handlePageSize = (data) => setPageSize(data);

  const keywordFilter = (searchData) => {
    AuctionDomains(1, searchData);
  };

  const editDomain = async (domainId) => {
    try {
      let response = await API.getProductData({
        id: domainId,
      });
      console.log(response, "rsp");
      navigate("/domain-auction", { state: response.data.map.domain });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteDomain = async (domainId) => {
    try {
      let response = await API.deleteProductData({
        ids: [domainId],
      });
      console.log(response, "rsp");
      AuctionDomains();
    } catch (error) {
      console.log(error);
    }
  };

  const AuctionDomains = async (
    pageNumber = 1,
    keyword = "",
    status,
    order
  ) => {
    try {
      let response = await API.getAuctionDomains({
        pageNumber: pageNumber,
        size: pageSize,
        keyword: keyword,
      });
      console.log(response, "rsp");

      setAuctionDomain(response.data.map.domains);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    AuctionDomains();
  }, [pageSize]);
  return (
    <div>
      {/* <div className="container"> */}
      <div className="pagebody">
        <div className="page_tittle">
          <h3 className="px-3">Auction Listing</h3>
        </div>
        <div
          className="d-flex"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="showing">
            <h6>Showing {pageSize} items</h6>
          </div>
          <div className="searchcol px-3">
            <input
              className="searchinput"
              type="text"
              placeholder="Search.. "
              onChange={(e) => keywordFilter(e.target.value)}
            />
          </div>
        </div>
        <div className="container table-container mt-3">
          {/* Tanstack Table  */}

          <TableComponent
            data={auctionDomain}
            columns={tableColumns}
            // sorting={sorting}
            // setSorting={setSorting}
            // filter={(e) => tableFilter(e)}
          />
          <div style={{ position: "relative", height: "50px" }}>
            {dataCount > pageSize && (
              <ProjectPagination
                handlePageClick={(e) => AuctionDomains(e.selected + 1)}
                pageCount={pageCount}
              />
            )}

            <div className="pageSizeContainer">
              <PageSize
                title={`${pageSize} items`}
                handleData={(e) => handlePageSize(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auction;
