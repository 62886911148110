import React from "react";

const HomeProcess = () => {
  return (
    <div>
      <div id="buy-sell">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <h2 className="buy-sell-heading">
                How to purchase &amp; sell domains through BestUrls ?
              </h2>
              <div className="line" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="key-block">
                <div
                  className="key-img"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_8_rg9cvt.png"
                    alt="agree"
                  />
                  <p className="key-block-para">Search our database</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="key-block">
                <div
                  className="key-img"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_9_golmzh.png"
                    alt="submit"
                  />
                  <p className="key-block-para">Find your dream domain</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="key-block">
                <div
                  className="key-img"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_10_gjivyn.png"
                    alt="deliver"
                  />
                  <p className="key-block-para">Purchase your desire domain</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="key-block">
                <div
                  className="key-img"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <img
                    src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1706188698/Asset_7_yhjvbd.png"
                    alt="done"
                  />
                  <p className="key-block-para">Done !</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <a href="#" className="preview" style={{ display: "inline" }}>
                Get Started Now
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HomeProcess;
