import React, { useState, useEffect } from "react";
import * as API from "../../../util/url";
import PageSize from "../../../Common/PageSize";
import ProjectPagination from "../../../Common/Pagination/ProjectPagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AdminGetMakeOffer = () => {
  const [accountData, setAccountData] = useState([]);
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const getAllAccount = async (pageNumber = 1, keyword = "", status, order) => {
    try {
      let response = await API.getMakeOffer({
        pageNumber: pageNumber,
        size: pageSize,
      });
      console.log(response, "rvm");
      setAccountData(response.data.map.makeOffers);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };

  const viewMessageHandler = (data) => {
    navigate("/power/dashboard/admin-make-offer-message", { state: data });
  };

  const handlePageSize = (data) => setPageSize(data);

  useEffect(() => {
    getAllAccount();
  }, [pageSize]);
  const keywordFilter = (searchData) => {
    getAllAccount(1, searchData);
  };

  return (
    <div>
      <div className="page_tittle">
        <h3 className="px-3">Get Make Offer</h3>
      </div>
      <div
        className="d-flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="showing">
          <h6>Showing {pageSize} items</h6>
        </div>
        <div className="searchcol px-3">
          <input
            className="searchinput"
            type="text"
            placeholder="Search.. "
            onChange={(e) => keywordFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="table-container container">
            <table className="table tablediv table-hover acutiontble table-striped">
              <thead className="headertable">
                <tr>
                  <th scope="col">Product Name</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Product Type</th>
                  <th scope="col">Offer Type</th>
                  <th scope="col"> Recent Message</th>
                  <th scope="col"> Time Left</th>
                  <th scope="col"> View</th>
                  {/* <th scope="col"> Edit</th> */}
                </tr>
              </thead>
              <tbody>
                {accountData.map((data) => {
                  return (
                    <tr>
                      <td>{data.productName}</td>
                      <td>{data.username}</td>
                      <td>{data.type}</td>
                      <td>{data.offerType}</td>
                      <td>{data.message}</td>
                      <td>{moment(data.endDate).format("ll")}</td>

                      <td onClick={() => viewMessageHandler(data.id)}>
                        {" "}
                        <div className="button-update">view</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div style={{ position: "relative", height: "100px" }}>
        {dataCount > pageSize && (
          <ProjectPagination
            handlePageClick={(e) => getAllAccount(e.selected + 1)}
            pageCount={pageCount}
          />
        )}

        <div className="pageSizeContainer">
          <PageSize
            title={`${pageSize} items`}
            handleData={(e) => handlePageSize(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminGetMakeOffer;
