import React, { useEffect, useState } from "react";
import "./Auction.css";
import { NavLink } from "react-router-dom";
import Moment from "moment";
import TableComponent from "../../Common/TableComponent/TableComponent";
import * as API from "../../util/url";
import { TableFilterHook } from "../../Common/TableFilterHook";
import PageSize from "../../Common/PageSize";
import ProjectPagination from "../../Common/Pagination/ProjectPagination";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ApiCaller from "../../util/ApiCaller";

const DomainPortfolio = () => {
  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });
  const tableColumns = [
    {
      accessorKey: "domainName",
      header: "Product Name",
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "creationDate",
      header: "Creation Date",
      cell: (props) => (
        <div>{Moment(props.getValue()).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      accessorKey: "buyNowPrice",
      header: "	Buynow Price",
      cell: (props) => (
        <div>
          {" "}
          <InputGroup className="priceWidth">
            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
            <Form.Control
              type="number"
              aria-describedby="basic-addon1"
              defaultValue={props.getValue()}
              onChange={(e) =>
                handleInputChange(e, props.row.id, "buyNowPrice")
              }
            />
          </InputGroup>{" "}
        </div>
      ),
    },
    {
      accessorKey: "minimumPrice",
      header: "	Minimum Price",
      cell: (props) => (
        <div>
          <InputGroup className="priceWidth">
            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
            <Form.Control
              type="number"
              aria-describedby="basic-addon1"
              defaultValue={props.getValue()}
              onChange={(e) =>
                handleInputChange(e, props.row.id, "minimumPrice")
              }
            />
          </InputGroup>{" "}
        </div>
      ),
    },
    {
      accessorKey: "domainId",
      header: "Action",

      cell: (props) => (
        <div
          className="d-flex"
          style={{
            justifyContent: "space-around",
            alignItems: "center",
            height: "50px",
          }}
        >
          {" "}
          <div
            className="button-update"
            style={{ cursor: "pointer" }}
            onClick={() => updateDomainPortfolioHandler(props.row.original)}
          >
            Update
          </div>
          <div
            className="button-delete"
            onClick={() => deleteDomain(props.row.original)}
          >
            Delete
          </div>
        </div>
      ),
    },
    {
      accessorKey: "verified",
      header: "Status",
      cell: (props) => (
        <div className="badge-success">
          {props.getValue() === true ? "verified" : "rejected"}
        </div>
      ),
    },
  ];

  const [portfolioDomain, setPortfolioDomain] = useState([]);
  const [pageSize, setPageSize] = useState("10");
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  const keywordFilter = (searchData) => {
    DomainPortfolio(1, searchData);
  };

  const tableFilter = (data) => {
    TableFilterHook(data, DomainPortfolio);
  };
  const handlePageSize = (data) => setPageSize(data);

  const deleteDomain = async (data) => {
    console.log(data, "dt");
    const { domainId, domainName } = data;
    try {
      let response = await API.deleteDomainPortfolio({
        allData: {
          data: [
            {
              domainId,
              domainName,
            },
          ],
        },
      });
      // console.log(response,'rsvp');
      DomainPortfolio();
    } catch (error) {
      console.log(error);
    }
  };

  const updateDomainPortfolioHandler = async (data) => {
    console.log(data, "dt");
    const { buyNowPrice, currency, domainId, domainName, minimumPrice } = data;

    setApiInfo({
      path: () =>
        API.updateDomainPortfolio({
          allData: {
            data: [
              {
                buyNowPrice,
                currency,
                domainId,
                domainName,
                minimumPrice,
              },
            ],
          },
        }),
      redirectUrl: "/dashboard/domain-portfolio/",
    });
    // try {
    //   let response = await API.updateDomainPortfolio({
    //     allData: {
    //       data: [{
    //         buyNowPrice,
    //         currency,
    //         domainId,
    //         domainName,
    //         minimumPrice
    //       }]
    //     }
    //   })
    //   console.log(response,'rsvp');
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleInputChange = (e, rowIdx, field) => {
    const { value } = e.target;
    console.log(rowIdx, field);
    console.log(value, "vl");
    setPortfolioDomain((prevData) => {
      const newData = [...prevData];
      newData[rowIdx][field] = value;
      return newData;
    });
  };

  const DomainPortfolio = async (
    pageNumber = 1,
    keyword = "",
    status,
    order
  ) => {
    try {
      let response = await API.getDomainPortfolio({
        pageNumber: pageNumber,
        size: pageSize,
        keyword: keyword,
      });
      console.log(response, "rsp");

      setPortfolioDomain(response.data.map.domains);
      setPageCount(response.data.map.size / pageSize);
      setDataCount(response.data.map.size);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    DomainPortfolio();
  }, [pageSize]);
  return (
    <div>
      {/* <div className="container"> */}
      <div className="pagebody">
        <div className="page_tittle">
          <h3 className="px-3">Domain Portfolio</h3>
        </div>
        <div
          className="d-flex"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="showing">
            <h6>Showing {pageSize} items</h6>
          </div>
          <div className="searchcol px-3">
            <input
              className="searchinput"
              type="text"
              placeholder="Search.. "
              onChange={(e) => keywordFilter(e.target.value)}
            />
          </div>
        </div>
        <div className="container table-container mt-3">
          {/* Tanstack Table  */}

          <TableComponent
            data={portfolioDomain}
            columns={tableColumns}
            // sorting={sorting}
            // setSorting={setSorting}
            // filter={(e) => tableFilter(e)}
          />
          <div style={{ position: "relative", height: "50px" }}>
            {dataCount > pageSize && (
              <ProjectPagination
                handlePageClick={(e) => DomainPortfolio(e.selected + 1)}
                pageCount={pageCount}
              />
            )}

            <div className="pageSizeContainer">
              <PageSize
                title={`${pageSize} items`}
                handleData={(e) => handlePageSize(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <ApiCaller apiData={apiInfo} />
    </div>
  );
};

export default DomainPortfolio;
