import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./BiddingHistory.css";
import LandingNavbar from "../../Common/LandingNavbar";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import * as API from "../../util/url";
import moment from "moment";
import ApiCaller from "../../util/ApiCaller";

const BiddingHistory = () => {
  const [biddingData, setBiddingData] = useState([]);
  const domainUrl = localStorage.getItem("domainUrl");
  const url = useSelector((state) => state.bid);

  const [price, setPrice] = useState(0);
  const [apiInfo, setApiInfo] = useState({
    path: "",
    redirectUrl: "",
  });

  const getDomainDetail = async () => {
    try {
      let response = await API.getBiddingData({
        url: domainUrl,
      });
      setBiddingData(response.data.map);
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeHandler = (price) => {
    setPrice(price);
    if (price < biddingData.startingPrice) {
      document.getElementById("PriceError").style.display = "block";
    } else {
      document.getElementById("PriceError").style.display = "none";
    }
  };
  const placedBid = async () => {
    setApiInfo({
      path: () =>
        API.bidPlaced({
          id: biddingData.productId,
          price: price,
        }),
      redirectUrl: "/bidding-history",
    });
    setTimeout(() => {
      getDomainDetail();
    }, 2000);
  };
  useEffect(() => {
    getDomainDetail();
  }, []);

  return (
    <div>
      <LandingNavbar />
      <section id="BiddingHistory">
        <div className="container">
          <div>
            <div className="historyOuter pt-5 px-5">
              <div className="historyInner">
                <div className="text-start mb-2 bid-hist-heading">
                  Bidding History
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="card" style={{ border: "0px" }}>
                      <Table size="sm">
                        <thead>
                          <tr className="biddingHistoryHeading">
                            <th className="p-2">Bidder Name</th>
                            <th className="p-2">Price</th>
                            <th className="p-2">Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {biddingData.bidding?.map((data) => {
                            return (
                              <tr className="biddingHistoryBody">
                                <td>{data.userName}</td>
                                <td>${data.price}</td>
                                <td>
                                  {moment(data.auctionEndDate).format("LL")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <div className="modal-body p-0">
                        <div className="card border-0">
                          <div
                            className="card-header"
                            style={{ padding: "0 1.5rem" }}
                          >
                            <h3
                              className="pt-2"
                              style={{
                                fontSize: "16px",
                                fontWeight: "700",
                                background:
                                  "-webkit-linear-gradient(#00f5cd, #64b3f1)",
                                webkitBackgroundClip: "text",
                                webkitTextFillColor: "transparent",
                              }}
                            >
                              This Domain is for auction
                            </h3>
                          </div>
                          <div
                            className="card body p-4 pt-0 border-0"
                            style={{ textAlign: "justify" }}
                          >
                            <div
                              className="my-3 fw-700"
                              style={{ fontSize: "18px" }}
                            >
                              {biddingData.productName}
                            </div>
                            <div
                              className="d-flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div>
                                <div style={{ color: "#fff" }}>
                                  {biddingData.totalBids} Bid
                                </div>
                                <p>{biddingData.timeRemaining}</p>
                              </div>
                              <div>
                                <div>Current Price</div>
                                <p
                                  className="fw-700"
                                  style={{ color: "#01c065" }}
                                >
                                  SOL {biddingData.currentPrice}
                                </p>
                              </div>
                            </div>
                            <div className="mt-3">
                              <p style={{ color: "#6c757d" }}>Reserve met</p>

                              <div>
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    placeholder=""
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) =>
                                      onChangeHandler(e.target.value)
                                    }
                                  />
                                  <InputGroup.Text
                                    id="basic-addon2"
                                    onClick={placedBid}
                                    style={{
                                      background:
                                        "linear-gradient(90deg,#c766ef,#7928d2 51.04%,#2b0c52)",
                                      color: "white",
                                    }}
                                  >
                                    Place Bid
                                  </InputGroup.Text>
                                </InputGroup>
                                <div
                                  id="PriceError"
                                  className="errorMsg mt-1 priceError"
                                  style={{
                                    color: "red",
                                    textAlign: "left",
                                    fontSize: "13px",
                                  }}
                                >
                                  * Price must be greater than the $
                                  {biddingData.startingPrice}
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              Questions? Talk to a domain expert:{" "}
                            </div>
                            <div
                              className="text-center"
                              style={{ color: "#80ecff " }}
                            >
                              {" "}
                              +1 (425) 880-0977
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ApiCaller apiData={apiInfo} />
        </div>
      </section>
    </div>
  );
};

export default BiddingHistory;
