import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import "./SideNav.css";
const SideNav = () => {
  const { userName } = useSelector((state) => state.auth);
  const location = useLocation();

  const initialValues = {
    dashboard: false,
    profile: false,
    myListing: false,
    offer: false,
    transactionCenter: false,
    // watchList:false,
    pastPurchase: false,
    bidPlaced: false,
    domainSold: false,
  };

  const [sideActive, setSideActive] = useState(initialValues);

  useEffect(() => {
    console.log(location.pathname.split("/")[2], "loc");
    if (location.pathname?.split("/")[2] !== undefined) {
      if (
        location.pathname?.split("/")[2] === "draft" ||
        "domain-portfolio" ||
        "auction"
      ) {
        setSideActive({ ...initialValues, myListing: true });
      } else {
        setSideActive({
          ...initialValues,
          [location.pathname?.split("/")[2]]: true,
        });
      }
      // draft , domain-portfolio ,auction
    } else {
      setSideActive({ ...initialValues, dashboard: true });
    }
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dashboard"
      style={{ padding: "0px" }}
    >
      {/* <a className="navbar-brand" href="#">Navbar</a> */}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContents"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContents">
        <div className="w-100">
          <div className="profile-navbar">
            <div className="userimg">
              <img
                src="https://besturls.com/assets/images/users/149071.png"
                alt=""
              />
            </div>
            <div className="username">
              {" "}
              <h4 style={{ fontWeight: "600" }}>Welcome {userName}!</h4>
            </div>
          </div>
          <div className="profile-sidenav-data">
            <div
              className={
                sideActive.dashboard ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() =>
                setSideActive({ ...initialValues, dashboard: true })
              }
            >
              <div className="sidenavImage">
                {/* <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707480887/laptop_vs8osx.png"
                  alt=""
                /> */}
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712580774/laptop_vs8osx_2_1_ofuh0g.png"
                  alt=""
                />
              </div>
              <NavLink className="Links" to="/dashboard">
                <div className="">Dashboard</div>
              </NavLink>
            </div>

            <div
              className={
                sideActive.profile ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() => setSideActive({ ...initialValues, profile: true })}
            >
              <div className="sidenavImage">
                {/* <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707480887/user_g4lltu.png"
                  alt=""
                /> */}
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712580961/user_g4lltu_1_1_zggech.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/dashboard/profile">
                  My Profile
                </NavLink>
              </div>
            </div>
            <div
              onClick={() =>
                setSideActive({ ...initialValues, myListing: true })
              }
            >
              <div className="">
                <Accordion id="myListing" defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      className={
                        sideActive.myListing
                          ? "sidenavSingle active"
                          : "sidenavSingle"
                      }
                    >
                      <div className="sidenavImage">
                        {/* <img
                          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707541413/shopping-cart_jhdbla.png"
                          alt=""
                        /> */}
                        <img
                          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712661729/shopping-cart_jhdbla_1_1_lg41vm.png"
                          alt=""
                        />
                      </div>
                      My Listing
                    </Accordion.Header>
                    <Accordion.Body>
                      <NavLink className="item" to="/dashboard/auction">
                        Auction Listing
                      </NavLink>
                      <NavLink
                        className="item"
                        to="/dashboard/domain-portfolio"
                      >
                        Domain Portfolio
                      </NavLink>
                      {/* <NavLink className="item" to="/dashboard/draft">
                        My Draft
                      </NavLink> */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div
              className={
                sideActive.offer ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() => setSideActive({ ...initialValues, offer: true })}
            >
              <div className="sidenavImage">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816499/data-transfer_jhdsip_2_1_1_uyo50e.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/dashboard/offer">
                  Offer
                </NavLink>
              </div>
            </div>
            <div
              className={
                sideActive.transactionCenter
                  ? "sidenavSingle active"
                  : "sidenavSingle"
              }
              onClick={() =>
                setSideActive({ ...initialValues, transactionCenter: true })
              }
            >
              <div className="sidenavImage">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816499/data-transfer_jhdsip_2_1_1_uyo50e.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/dashboard/transfercenter">
                  Transfer Center
                </NavLink>
              </div>
            </div>
            {/* <div className={sideActive.watchList?"sidenavSingle active":"sidenavSingle"} onClick={()=>setSideActive({...initialValues,watchList:true})}>
            <div className="sidenavImage">
              <img
                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1705471913/icons8-eye-50_nfnbbs.png"
                alt=""
              />
            </div>
            <div className="">
              <NavLink className="Links" to="/dashboard/watchlist">
                Watch List
              </NavLink>
            </div>
          </div> */}
            <div
              className={
                sideActive.pastPurchase
                  ? "sidenavSingle active"
                  : "sidenavSingle"
              }
              onClick={() =>
                setSideActive({ ...initialValues, pastPurchase: true })
              }
            >
              <div className="sidenavImage">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712816782/wallet_vuwscq_1_1_nt5woa.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/dashboard/payment">
                  Past Purchases
                </NavLink>
              </div>
            </div>
            <div
              className={
                sideActive.bidPlaced ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() =>
                setSideActive({ ...initialValues, bidPlaced: true })
              }
            >
              <div className="sidenavImage">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712817020/auction_d0m8vg_1_1_jdnd2y.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/dashboard/bids">
                  Bids Placed
                </NavLink>
              </div>
            </div>
            <div
              className={
                sideActive.domainSold ? "sidenavSingle active" : "sidenavSingle"
              }
              onClick={() =>
                setSideActive({ ...initialValues, domainSold: true })
              }
            >
              <div className="sidenavImage">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1712818230/sold_nc6fxn_1_1_fl1aes.png"
                  alt=""
                />
              </div>
              <div className="">
                <NavLink className="Links" to="/dashboard/domain-sold">
                  Domain Sold
                </NavLink>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </nav>
  );
};

export default SideNav;
