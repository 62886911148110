import React, { useEffect, useState } from "react";
import './Profile.css'
import Contact from "./Contact";
import Profiletab from "./Profiletab";
import Verification from "./Verification";
const Profile = () => {
  const initialState = {
    profile: false,
    verify: false,
    contact: false
  }
  const [tabChange, setTabChange] = useState(initialState);
  useEffect(() => {
    setTabChange({ ...initialState, profile: true })
  }, [])



  return (
    <div>
      <div className="container profilecontainer">
        <div className="row">

          <div className=" col-md-12 col-12 mt-5 mb-5">
            <nav aria-label="...">
              <ul className="pagination paginations pagination-lg">
                <li className={tabChange.profile ? "profile-page-item page_item active" : "profile-page-item"} aria-current="page">
                  <p className="page-link pages_link" onClick={() => setTabChange({ ...initialState, profile: true })}>
                    Profile
                  </p>
                </li>
                <li className={tabChange.contact ? "profile-page-item page_item active" : "profile-page-item"} >
                  <p className="page-link pages_link" onClick={() => setTabChange({ ...initialState, contact: true })} >
                    Contact
                  </p>
                </li>
                <li className={tabChange.verify ? "profile-page-item page_item active" : "profile-page-item"} >
                  <p className="page-link pages_link" onClick={() => setTabChange({ ...initialState, verify: true })} >
                    Verification
                  </p>
                </li>
              </ul>

              {tabChange.profile && <Profiletab />}
              {tabChange.contact && <Contact />}
              {tabChange.verify && <Verification />}
            </nav>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Profile;
